/*
tr#boardColor{
	margin: 10vh 0;
	padding: 10vh 0;
	color: black;
}
*/

.size15 td {
    height: 5vh;
    width: 5vw;
    font-size: 200%;
}

.size19 td {
    height: 4vh;
    width: 4vw;
    font-size: 150%;
}

div.vertical {
    background-color: var(--Lines);
    width: 1px;
    height: 100%;
    position: absolute;
    left: 50%;
    bottom: 0%;
}

div.horizontal {
    background-color: var(--Lines);
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 50%;
}

/*transform: translate(0,-2800%);}*/
.row0 div.vertical {
    background-color: var(--Lines);
    width: 1px;
    height: 50%;
    position: absolute;
}

.rowLast div.vertical {
    background-color: var(--Lines);
    width: 1px;
    height: 50%;
    bottom: 50%;
    position: absolute;
}

.col0 div.horizontal {
    background-color: var(--Lines);
    width: 50%;
    height: 1px;
    left: 50%;
    position: absolute;
}

.colLast div.horizontal {
    background-color: var(--Lines);
    width: 50%;
    height: 1px;
    left: 0%;
    position: absolute;
}

#board {
    width: 70%;
    /*accomodate both player status tables at 10% each*/

    margin-left: auto;
    margin-right: auto;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

#board td {
    border-radius: 50% 50%;
}

/*Hover css is implemented through BoardCell component, now.*/
/* #board td:hover {
    border-radius: 50% 50%;
    background-color: var(--Circle);
}
#board td:hover div {
    background-color: var(--Circle);
} */

table#board {
    display: inline-table;
}

/*
.board td:active{
        border-radius: 50% 50%;
         background-color: red;}
.board td:active div{
       background-color: red;}
	   */