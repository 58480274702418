#leaderboard table {
	margin: 1vh auto;
	width: 80vw;

	/*prevent text highlights*/
	-webkit-user-select: none;
	/* Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE10+/Edge */
	user-select: none;
	/* Standard */
}

#leaderboard table caption {
	text-align: center;
	font-size: 150%;
	margin: 3vh auto;
}